// Sidebar widget dark color scheme

.app-sidebar {

  &--overlay {
    &:after {
      content: '';
      background: $black;
      opacity: .45;
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & > * {
      z-index: 4;
    }
  }

  &--dark {
    .app-sidebar--widget {

      .app-sidebar-spacer {
        color: $white !important;
      }

      .text-danger svg {
        color: $white;
      }

      .badge {
        background: rgba($white, .15) !important;
        color: $white !important;
      }
    }
  }
}
